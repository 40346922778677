import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'

import { Container } from 'react-bootstrap'

const NotFoundPage = () => (
  <Layout>
    <Container style={{ paddingTop: 130 }}>
      <SEO title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>
        Go to <Link to="/">main</Link>.
      </p>
    </Container>
  </Layout>
)

export default NotFoundPage
